import * as React from "react";
import { Box } from '@mui/material';
import '../App.css';
import Appbar from '../component/appbar';
import Foot from '../component/foot';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import { Box } from '@mui/material';

 function Button_click() {

}

function Page2() {
const [age, setAge] = React.useState("");
const handleChange = (event) => {
      setAge(event.target.value);
};

const Button_click = () => {
            alert(age);
    };
    return (
        <div className="App">
            <Appbar />
            <header className='App-header'>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', py: 7 }}>
                    <Card sx={{ maxWidth: 500, m: 3 }}>
                        <CardMedia
                            sx={{ height: 200 }}
                            image="https://i.pinimg.com/1200x/1b/4c/1f/1b4c1f1ba397db173d0a112d89a0cf38.jpg"
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Lizard
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                <Box sx={{ minWidth: 300 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="warning" onClick={Button_click}>Submit</Button>
                            <Button variant="outlined" color="error">Error</Button>
                        </CardActions>
                    </Card>
                </Box>
                <p>{age}</p>
            </header>
            <Foot />
        </div>
    );
}
export default Page2;
